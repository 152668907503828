import { Pier } from "../models/pier.model";

export class LoadPiers {
  public static readonly type = "[Piers] load piers";
  constructor(public filters?: any) {}
}

export class AddPier {
  public static readonly type = "[Piers] add pier";
  constructor(
    public payload: Pier,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdatePier {
  public static readonly type = "[Piers] update pier";
  constructor(
    public payload: Pier,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemovePier {
  public static readonly type = "[Piers] remove pier";
  constructor(
    public pierId: number,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}
