import { Injectable } from "@angular/core";
import { Action, Actions, Selector, State, StateContext, ofAction } from "@ngxs/store";
import { takeUntil, tap } from "rxjs/operators";
import { FilterAction } from "../../../../shared/components/filters/filters.component";
import { AddFilter, SetFilter } from "../../../../shared/states/prefill-data/prefill-data.actions";
import { getFilterData, getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults, SetError } from "../../admin.state";
import { PiersService } from "../piers.service";
import { AddPier, LoadPiers, RemovePier, UpdatePier } from "./piers.actions";
import { PiersStateModel } from "./piers.model";

@State<PiersStateModel>({
  name: "piers",
  defaults: AdminPagesStateDefaults
})
@Injectable()
export class PiersState {
  
  isFiltered: boolean;

  constructor(
    private piersService: PiersService,
    private actions$: Actions
  ) {}

  @Selector() static piers(state: PiersStateModel) { return state.items; }
  @Selector() static loading(state: PiersStateModel) { return state.loading; }
  @Selector() static saving(state: PiersStateModel) { return state.saving; }

  @Action(LoadPiers)
  loadPiers(ctx: StateContext<PiersStateModel>, { filters }) {
    ctx.patchState({
      items: [],
      loading: true
    });

    return this.piersService.getPiers(filters?.items || {}).pipe(
      tap(
        (response) => {
          this.isFiltered = filters?.hasEnabledFilters;

          ctx.patchState({ items: response.items, loaded: true, loading: false });

          if (!this.isFiltered) {
            const portsFilters = getFilterData(response, "AvailablePorts");
            const terminalsFilters = getFilterData(response, "AvailableTerminals");

            ctx.dispatch(new SetFilter("filteredPorts", portsFilters));
            ctx.dispatch(new SetFilter("filteredTerminals", terminalsFilters));
          }
        },
        (err) => {
          ctx.patchState({ loading: false, items: [] });
          ctx.dispatch(new SetError({ loading: err }));
        }
      ),
      takeUntil(this.actions$.pipe(ofAction(FilterAction)))
    );
  }

  @Action(AddPier)
  addPier(ctx: StateContext<PiersStateModel>, action: AddPier) {
    const state = ctx.getState();
    ctx.patchState({ saving: true });

    return this.piersService.create(action.payload).subscribe(
      (response) => {
        action.callbackSuccess();

        ctx.dispatch(
          new AddFilter("filteredPorts", {
            key: response.port.id,
            value: response.port.name
          })
        );
        ctx.dispatch(
          new AddFilter("filteredTerminals", {
            key: response.terminal.id,
            value: response.terminal.name
          })
        );

        if (this.isFiltered) {
          ctx.dispatch(new LoadPiers()).subscribe((res: any) => {
            let piers = [...res.piers.items];
            piers.pop();

            ctx.patchState({
              items: [response, ...piers],
              saving: false
            });
          });
        } else {
          ctx.patchState({
            items: [response, ...state.items],
            saving: false
          });
        }
      },
      (error) => {
        ctx.patchState({ loading: false, saving: false });
        action.callbackError();
      }
    );
  }

  @Action(UpdatePier)
  updatePier(ctx: StateContext<PiersStateModel>, action: UpdatePier) {
    const state = ctx.getState();
    ctx.patchState({ saving: true });

    return this.piersService.update(action.payload).subscribe(
      (response) => {
        action.callbackSuccess();
        ctx.patchState({
          items: getUpdatedItems(response, state.items),
          saving: false
        });
      },
      (error) => {
        ctx.patchState({ saving: false });
        action.callbackError();
      }
    );
  }

  @Action(RemovePier)
  removePier(ctx: StateContext<PiersStateModel>, action: RemovePier) {
    const state = ctx.getState();

    return this.piersService.remove(action.pierId).subscribe(
      () => {
        action.callbackSuccess();
        const updated = state.items.filter(item => {
          return item.id !== action.pierId;
        });
        ctx.patchState({ items: updated });
      },
      (error) => {
        action.callbackError();
      }
    );
  }
}
